import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"

import classnames from "classnames"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { get, post } from "helpers/api_helper"
import ComposicaoFamiliarForm from "./composicao-familiar"
import { uniqueId } from "lodash"
import  Loading  from "../../../components/loading"

const AlunoWizard = () => {

  document.title = "Cadastrar Aluno | Casa São José";

  const [popUpComposicaoFamiliarForm, setPopUpComposicaoFamiliarForm] = useState(false)
  const [fichaComposicao, setFichaComposicao] = useState({ genitor: "Não", escolaridade: "Não Alfabetizado", condicao_trabalho: "Trabalha" });
  const [activeTab, setactiveTab] = useState(1)
  const [aluno, setAluno] = useState({

    escola: "EBM José Jacinto Cardoso",
    reprovou: "Não",
    participa_outro_projeto: "Não",
    sexo: "Masculino",
    raca_cor: "Não Informado",
    escolaridade: "1°EF",
    turno: "Matutino",
    reprovou: "Não",
    participa_outro_projeto: "Não",
    forma_acesso: "Pela área da Educação",
    ficha_saude: {
      deficiencia: "Não",
      deficiencia_qual: "Não",
      deficiencia_laudo: "Não",
      alergia_restricao: "Não",
      alergia_qual: "Não",
      usa_medicamentos: "Não",
      medicamentos_qual: "Não",
      faz_tratamento: "Não",
      tratamento_qual: "Não",
      faz_fono: "Não",
      motivo_fono: "Não",
      faz_psicologico: "Não",
      motivo_psicologico: "Não",
      vacina_covid: "Não",
      n_vacinas_covid: false
    },
    ficha_moradia: {
      moradia_condicao: "Própria",
      moradia_agua_encanada: "Não",
      moradia_rede_esgoto: "Não",
      moradia_coleta_lixo: "Não",
      moradia_tipo_construcao: "Alvenaria",
    },
    ficha_composicao: [],
    ficha_adicionais: {
      familia_refugiada: "Não",
      
    },
    ficha_socio: {
      foi_atendido: "Não foi atendido(a)",
      cadastro_unico: "Não",
      beneficio_socio: false,
      motivo_atendimento: null
    }
  });


  const [condicao, setCondicao] = useState([
    "Própria",
    "Cedida",
    "Alugada"
  ])

  const [escolas, setEscolas] = useState([
    "EBM José Jacinto Cardoso",
    "EEB Simão José Hess",
    "EEB Hilda Teodoro Vieira",
    "EEB Beatriz de Souza Britto",
    "Colégio de Aplicação UFSC",
    "EEB Pe. João Alfredo Rohr",
    "Colégio Tradição",
    "Instituto Estadual de Educação",
    "Outros"])

  const [beneficios, setBenefocios] = useState([
    "Bolsa Família",
    "Auxilio Brasil",
    "BPC",
    "Cartão Social",
    "Pensao",
    "Outros"
  ])

  const [passedSteps, setPassedSteps] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 7) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const parseBoolean = (value) => {
    if(typeof value == "string")
      return value.toLocaleLowerCase()== "sim"

    return value === 1;
  }
  

  const [loadingCreate, setLoadingCreate] = useState(false)

  const handleSubmit = async () => {
    setLoadingCreate(true)
    const response = await post("/api/aluno", aluno);
    window.location = `/aluno/${response["id"]}`;
    setLoadingCreate(false)
  }

  const addComposicao = (composicao) => {
    let tempComposicao = aluno.ficha_composicao;

    if (typeof composicao.key === "undefined")
      composicao.key = uniqueId();

    tempComposicao = tempComposicao.filter(comp => comp.key !== composicao.key);
    tempComposicao.push(composicao);

    setAluno({ ...aluno, ficha_composicao: tempComposicao });
    setFichaComposicao({});
    setPopUpComposicaoFamiliarForm(false);
  }

  const buscaCep = async (cep) => {
    const response = await get(`https://viacep.com.br/ws/${cep}/json`);

    console.log(response);
    setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, endereco: response.logradouro,bairro: response.bairro } })
  }

  return (
    <React.Fragment>
      <div className="page-content">
      {loadingCreate &&
        <Loading />
      }
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Ficha Aluno" />

          <Row>
            <Col >
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Casa São Jose</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                          >
                            <span className="number">1.</span> Aluno
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                          >
                            <span className="number">2.</span> Saúde
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                          >
                            <span className="number">3.</span> Moradia
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                            }}
                          >
                            <span className="number">4.</span> Comp Familiar
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({ current: activeTab === 5 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 5 })}
                            onClick={() => {
                              setactiveTab(5)
                            }}
                          >
                            <span className="number">5.</span> Adicionais
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({ current: activeTab === 6 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 6 })}
                            onClick={() => {
                              setactiveTab(6)
                            }}
                          >
                            <span className="number">6.</span> Socio Assistencial
                          </NavLink>
                        </NavItem>

                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Nº da Matrícula
                                  </Label>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Nº da Matrícula..."
                                    onBlur={(e) => setAluno({ ...aluno, n_matricula: e.target.value })}
                                  />
                                </div>

                              </Col>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Data de ingresso
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="date"
                                    defaultValue=""
                                    id="example-date-input"
                                    onBlur={(e) => setAluno({ ...aluno, data_do_ingresso: e.target.value })}
                                  />
                                </div>

                              </Col>

                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Data de nascimento
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="date"
                                    defaultValue=""
                                    id="example-date-input"
                                    onBlur={(e) => setAluno({ ...aluno, data_nascimento: e.target.value })}
                                  />
                                </div>
                              </Col>
                              <Col >
                                <div className="col-sm-auto">
                                  <Label for="basicpill-lastname-input2">
                                    Sexo
                                  </Label>
                                  <select className="form-select" onChange={(e) => setAluno({ ...aluno, sexo: e.target.value })}>

                                    <option selected value="masculino">Masculino</option>
                                    <option value="feminino">Feminino</option>
                                  </select>
                                </div>
                              </Col>
                              <Col >
                                <div className="col-sm-auto">
                                  <Label for="basicpill-lastname-input2">
                                    Raça/Cor
                                  </Label>
                                  <select className="form-select" onChange={(e) => setAluno({ ...aluno, raca_cor: e.target.value })}>

                                    <option value="Branca">Branca</option>
                                    <option value="Parda">Parda</option>
                                    <option value="Negra">Negra</option>
                                    <option value="Indígena">Indígena</option>
                                    <option value="Amarela">Amarela</option>
                                    <option selected value="Não informado">Não informado</option>
                                  </select>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-phoneno-input3">
                                    Nome completo
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-phoneno-input3"
                                    placeholder="Nome completo..."
                                    onBlur={(e) => setAluno({ ...aluno, nome_completo: e.target.value })}
                                  />
                                </div>
                              </Col>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-email-input4">
                                    Nome social
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="Nome social..."
                                    onBlur={(e) => setAluno({ ...aluno, nome_social: e.target.value })}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-email-input4">
                                    Naturalidade
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="Naturalidade..."
                                    onBlur={(e) => setAluno({ ...aluno, naturalidade: e.target.value })}
                                  />
                                </div>
                              </Col>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-email-input4">
                                    CPF
                                  </Label>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="CPF..."
                                    onBlur={(e) => setAluno({ ...aluno, cpf: e.target.value })}
                                  />
                                </div>
                              </Col>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-email-input4">
                                    RG
                                  </Label>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="RG..."
                                    onBlur={(e) => setAluno({ ...aluno, rg: e.target.value })}
                                  />
                                </div>
                              </Col>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-email-input4">
                                    NIS
                                  </Label>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="NIS..."
                                    onBlur={(e) => setAluno({ ...aluno, nis: e.target.value })}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Escolaridade
                                  </Label>
                                  <select className="form-select" onChange={(e) => setAluno({ ...aluno, escolaridade: e.target.value })}>

                                    <option value="1°EF">1°EF</option>
                                    <option value="2°EF">2°EF</option>
                                    <option value="3°EF">3°EF</option>
                                    <option value="4°EF">4°EF</option>
                                    <option value="5°EF">5°EF</option>
                                    <option value="6°EF">6°EF</option>
                                    <option value="7°EF">7°EF</option>
                                    <option value="8°EF">8°EF</option>
                                    <option value="9°EF">9°EF</option>
                                    <option value="1°EM">1°EM</option>
                                    <option value="2°EM">2°EM</option>
                                    <option value="3°EM">3°EM</option>

                                  </select>
                                </div>

                              </Col>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Turno
                                  </Label>
                                  <select className="form-select" onChange={(e) => setAluno({ ...aluno, turno: e.target.value })}>

                                    <option value="Matutino">Matutino</option>
                                    <option value="Vespertino">Vespertino</option>
                                    <option value="Noturno">Noturno</option>
                                  </select>
                                </div>

                              </Col>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Escola
                                  </Label>
                                  <select className="form-select" onChange={(e) => setAluno({ ...aluno, escola: e.target.value })}>
                                    {escolas.map((escola, index) => {
                                      return <option selected={aluno.escola == escola} key={index} value={escola}>{escola}</option>
                                    })}
                                  </select>
                                </div>

                              </Col>
                              {(!escolas.includes(aluno.escola) || aluno.escola == "Outros") &&
                                <Col>
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Qual
                                    </Label>

                                    <Input
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      rows="1"
                                      placeholder="Nome da escola..."
                                      onBlur={(e) => setAluno({ ...aluno, escola: e.target.value })}
                                    />

                                  </div>
                                </Col>}
                            </Row>
                            <Row>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Reprovou
                                  </Label>
                                  <select className="form-select" onChange={(e) => setAluno({ ...aluno, reprovou: e.target.value })}>

                                    <option value="Sim">Sim</option>
                                    <option selected value="Não">Não</option>
                                  </select>
                                </div>

                              </Col>
                              {parseBoolean(aluno.reprovou) &&
                                <Col>
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Aonde reprovou
                                    </Label>

                                    <Input
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      rows="1"
                                      placeholder="Aonde reprovou..."
                                      onBlur={(e) => setAluno({ ...aluno, aonde_reprovou: e.target.value })}
                                    />
                                  </div>
                                </Col>}

                              <Col>
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Participa de algum outro projeto
                                  </Label>
                                  <select className="form-select" onChange={(e) => setAluno({ ...aluno, participa_outro_projeto: e.target.value })}>

                                    <option value="Sim">Sim</option>
                                    <option selected value="Não">Não</option>
                                  </select>
                                </div>


                              </Col>
                              {parseBoolean(aluno.participa_outro_projeto) &&
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Quais dias
                                    </Label>

                                    <Input
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      rows="1"
                                      placeholder="Quais dias..."
                                      onBlur={(e) => setAluno({ ...aluno, dias_projeto: e.target.value })}
                                    />
                                  </div>
                                </Col>}
                            </Row>
                            <Row>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Forma de acesso
                                  </Label>
                                  <select className="form-select" onChange={(e) => setAluno({ ...aluno, forma_acesso: e.target.value })}>
                                    <option value="Pela área da Educação">Pela área da Educação</option>
                                    <option value="Pela área da Saúde">Pela área da Saúde</option>
                                    <option value="Pelo Conselho Tutelar">Pelo Conselho Tutelar</option>
                                    <option value="Pelo Poder Judiciário">Pelo Poder Judiciário</option>
                                    <option value="Pelo Sistema de Garantia de Direitos">Pelo Sistema de Garantia de Direitos</option>
                                    <option value="Busca Ativa da Equipe">Busca Ativa da Equipe</option>
                                    <option value="Demanda Espontâne">Demanda Espontânea</option>
                                    <option value="Outras Políticas Setoriais">Outras Políticas Setoriais</option>
                                    <option value="Serviços da Proteção Social Básica">Serviços da Proteção Social Básica</option>
                                    <option value="Serviços da Proteção Social Especial">Serviços da Proteção Social Especial</option>
                                  </select>

                                </div>



                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Possui alguma Deficiência
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                      if (e.target.value == "Não")
                                        setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, deficiencia: "Não", deficiencia_qual: null } })
                                      if (e.target.value == "Sim")
                                        setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, deficiencia: "Sim" } })
                                    }}>

                                      <option selected={aluno.ficha_saude.deficiencia === "Sim" ? "selected" : ""}value="Sim">Sim</option>
                                      <option selected={aluno.ficha_saude.deficiencia === "Não" ? "selected" : ""}value="Não">Não</option>
                                    </select>
                                  </div>

                                </Col>
                                {parseBoolean(aluno.ficha_saude.deficiencia) &&
                                  <>
                                    <Col>
                                      <div className="mb-3">
                                        <Label for="basicpill-lastname-input2">
                                          Qual
                                        </Label>

                                        <Input
                                          id="basicpill-address-input1"
                                          className="form-control"
                                          rows="1"
                                          placeholder="Qual deficiência..."
                                          onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, deficiencia_qual: e.target.value } })}
                                        />
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="mb-3">
                                        <Label for="basicpill-lastname-input2">
                                          Possui laudo técnico
                                        </Label>

                                        <select className="form-select" onChange={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, deficiencia_laudo: e.target.value } })}>

                                          <option value="Sim">Sim</option>
                                          <option selected value="Não">Não</option>
                                        </select>
                                      </div>
                                    </Col>
                                  </>
                                }
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Tem Alergias / Restrição alimentar
                                    </Label>
                                    <select className="form-select" onChange={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, alergia_restricao: e.target.value} })}>

                                      <option value="Sim">Sim</option>
                                      <option selected value="Não">Não</option>
                                    </select>
                                  </div>

                                </Col>
                                {parseBoolean(aluno.ficha_saude.alergia_restricao) &&
                                  <Col>
                                    <div className="mb-3">
                                      <Label for="basicpill-lastname-input2">
                                        Quais
                                      </Label>

                                      <Input
                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        placeholder="Alergias/Restrição..."
                                        onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, alergia_qual: e.target.value } })}
                                      />
                                    </div>
                                  </Col>
                                }
                              </Row>
                              <Row>
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Usa medicamentos de uso contínuo
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                      if (e.target.value == "Sim")
                                        setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, usa_medicamentos: 1 } })
                                      if (e.target.value == "Não")
                                        setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, usa_medicamentos: 0, medicamentos_qual: null } })
                                    }}
                                    >
                                      <option value="Sim">Sim</option>
                                      <option selected value="Não">Não</option>
                                    </select>
                                  </div>

                                </Col>
                                {parseBoolean(aluno.ficha_saude.usa_medicamentos) &&
                                  <Col>
                                    <div className="mb-3">
                                      <Label for="basicpill-lastname-input2">
                                        Quais
                                      </Label>

                                      <Input
                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        placeholder="Quais medicamentos..."
                                        onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, medicamentos_qual: e.target.value } })}
                                      />
                                    </div>
                                  </Col>}
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Faz tratamento
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                      if (e.target.value == "Sim")
                                        setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, faz_tratamento: 1 } })
                                      if (e.target.value == "Não")
                                        setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, faz_tratamento: 0, tratamento_qual: null } })

                                    }}>

                                      <option value="Sim">Sim</option>
                                      <option selected value="Não">Não</option>
                                    </select>
                                  </div>

                                </Col>
                                {parseBoolean(aluno.ficha_saude.faz_tratamento) &&
                                  <Col>
                                    <div className="mb-3">
                                      <Label for="basicpill-lastname-input2">
                                        Qual
                                      </Label>

                                      <Input
                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        placeholder="Qual tratamento..."
                                        onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, tratamento_qual: e.target.value } })}

                                      />
                                    </div>
                                  </Col>}
                              </Row>
                              <Row>
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Já fez acompanhamento fonoaudiológico
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                      if (e.target.value == "Sim")
                                        setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, faz_fono: 1 } })
                                      if (e.target.value == "Não")
                                        setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, faz_fono: 0, motivo_fono: null } })
                                    }}>

                                      <option value="Sim">Sim</option>
                                      <option selected value="Não">Não</option>
                                    </select>
                                  </div>

                                </Col>
                                {parseBoolean(aluno.ficha_saude.faz_fono) &&
                                  <Col>
                                    <div className="mb-3">
                                      <Label for="basicpill-lastname-input2">
                                        Qual motivo
                                      </Label>

                                      <Input
                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        placeholder="Qual motivo..."
                                        onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, motivo_fono: e.target.value } })}
                                      />
                                    </div>
                                  </Col>}
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Já fez acompanhamento psicológico
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                      if (e.target.value == "Sim")
                                        setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, faz_psicologico: 1 } })
                                      if (e.target.value == "Não")
                                        setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, faz_psicologico: 0, motivo_psicologico: null } })
                                    }}>

                                      <option value="Sim">Sim</option>
                                      <option selected value="Não">Não</option>
                                    </select>
                                  </div>

                                </Col>
                                {parseBoolean(aluno.ficha_saude.faz_psicologico) &&
                                  <Col>
                                    <div className="mb-3">
                                      <Label for="basicpill-lastname-input2">
                                        Qual motivo
                                      </Label>

                                      <Input
                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        placeholder="Qual motivo..."
                                        onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, motivo_psicologico: e.target.value } })}
                                      />
                                    </div>
                                  </Col>}
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Centro de saúde que a família frequenta
                                    </Label>

                                    <Input
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      rows="1"
                                      placeholder="Qual centro..."
                                      onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, qual_centro_saude_familia: e.target.value } })}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Nº do Cartão do SUS
                                    </Label>

                                    <Input
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      rows="1"
                                      type="number"
                                      placeholder="Cartão do SUS..."
                                      onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, n_cartao_sus: e.target.value } })}
                                    />
                                  </div>
                                </Col>
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Já tomou vacina da COVID
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                      if (e.target.value == "Sim")
                                        setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, vacina_covid: 1 } })
                                      if (e.target.value == "Não")
                                        setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, vacina_covid: 0, n_vacinas_covid: null } })
                                    }}>

                                      <option value="Sim">Sim</option>
                                      <option selected value="Não">Não</option>
                                    </select>
                                  </div>

                                </Col>
                                {parseBoolean(aluno.ficha_saude.vacina_covid) &&
                                  <Col>
                                    <div className="mb-3">
                                      <Label for="basicpill-lastname-input2">
                                        Numero de doses
                                      </Label>

                                      <Input
                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        type="number"
                                        placeholder="Doses..."
                                        onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, n_vacinas_covid: e.target.value } })}
                                      />
                                    </div>
                                  </Col>}
                              </Row>
                            </Form>
                          </div>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div>
                            <Form>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                      Endereço
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-namecard-input11"
                                      placeholder="Endereço..."
                                      defaultValue={aluno.ficha_moradia.endereco}
                                      onBlur={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, endereco: e.target.value } })}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                      Numero
                                    </Label>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      id="basicpill-namecard-input11"
                                      placeholder="Numero..."
                                      onBlur={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, endereco_numero: e.target.value } })}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                      CEP
                                    </Label>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      id="basicpill-namecard-input11"
                                      placeholder="CEP..."
                                      onBlur={async (e) => {
                                        setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, cep: e.target.value } });
                                        await buscaCep(e.target.value);
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                      Bairro
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-namecard-input11"
                                      placeholder="Bairro..."
                                      defaultValue={aluno.ficha_moradia.bairro}
                                      onBlur={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, bairro: e.target.value } })}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-cardno-input12">
                                      Ponto de referência
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-cardno-input12"
                                      placeholder="Ponto de referência..."
                                      onBlur={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, ponto_referencia: e.target.value } })}
                                    />
                                  </div>
                                </Col>
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Condições de moradia
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                      if (e.target.value == "Cedida")
                                        setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_valor: null, moradia_condicao: "Cedida" } })
                                      if (e.target.value == "Própria")
                                        setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_valor: null, moradia_condicao: "Própria" } })
                                      if (e.target.value == "Alugada")
                                        setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_condicao: "Alugada" } })

                                    }}>

                                      {condicao.map((moradia_condicao, index) => {
                                        return <option selected={aluno.ficha_moradia.moradia_condicao == moradia_condicao} key={index} value={moradia_condicao}>{moradia_condicao}</option>
                                      })}
                                    </select>
                                  </div>

                                </Col>
                                {(!condicao.includes(aluno.ficha_moradia.moradia_condicao) || aluno.ficha_moradia.moradia_condicao == "Alugada") &&


                                  <Col>
                                    <div className="mb-3">
                                      <Label for="basicpill-lastname-input2">
                                        Valor do aluguel
                                      </Label>

                                      <Input
                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        type="number"
                                        placeholder="Valor..."
                                        onBlur={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_valor: e.target.value } })}

                                      />

                                    </div>
                                  </Col>}
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Água encanada
                                    </Label>
                                    <select className="form-select" onChange={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_agua_encanada: e.target.value } })}>
                                      <option value="Sim">Sim</option>
                                      <option selected value="Não">Não</option>

                                    </select>
                                  </div>

                                </Col>
                              </Row>
                              <Row>
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Rede de esgoto
                                    </Label>
                                    <select className="form-select" onChange={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_rede_esgoto: e.target.value } })}>
                                      <option value="Sim">Sim</option>
                                      <option selected value="Não">Não</option>

                                    </select>
                                  </div>

                                </Col>
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Coleta de lixo
                                    </Label>
                                    <select className="form-select" onChange={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_coleta_lixo: e.target.value } })}>
                                      <option value="Não">Não</option>
                                      <option value="Sim">Sim</option>
                                    </select>
                                  </div>

                                </Col>
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Tipo de Construção
                                    </Label>
                                    <select className="form-select" onChange={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_tipo_construcao: e.target.value } })}>
                                      <option selected value="Alvenaria">Alvenaria</option>
                                      <option value="Madeira">Madeira</option>
                                      <option value="Mista">Mista</option>

                                    </select>
                                  </div>

                                </Col>
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-cardno-input12">
                                      Quais Cômodos
                                    </Label>

                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-cardno-input12"
                                      placeholder="Quais cômodos..."
                                      onBlur={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_quais_comodos: e.target.value } })}

                                    />


                                  </div>

                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>

                          <div className="text-end mb-2">
                            {aluno.ficha_composicao.length > 0 &&
                              <button className="btn btn-primary waves-effect waves-light btn btn-primary" onClick={() => setPopUpComposicaoFamiliarForm(true)}>
                                Adicionar Composição
                              </button>
                            }
                          </div>

                          <Modal isOpen={popUpComposicaoFamiliarForm}
                            size="xl"
                            toggle={() => {
                              setPopUpComposicaoFamiliarForm(!popUpComposicaoFamiliarForm);
                            }}>
                            <ModalHeader>
                              {"Composição Familiar"}
                            </ModalHeader>
                            <ModalBody>
                              <ComposicaoFamiliarForm fichaComposicao={fichaComposicao} addComposicao={composicao => addComposicao(composicao)} />
                            </ModalBody>
                          </Modal>

                          {aluno.ficha_composicao.length > 0 ?
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Nome</th>
                                  <th>Parentesco</th>
                                  <th>Data de Nascimento</th>
                                  <th>Escolaridade</th>
                                  <th>Profissão</th>
                                  <th>Genitor</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {aluno.ficha_composicao.map((ficha, index) => {
                                  return <tr key={index}>
                                    <td>{ficha.nome}</td>
                                    <td>{ficha.grau_parentesco}</td>
                                    <td>{ficha.data_nascimento}</td>
                                    <td>{ficha.escolaridade}</td>
                                    <td>{ficha.funcao}</td>
                                    <td>{ficha.genitor}</td>
                                    <td className="text-end">
                                      <button type="button" className="btn btn-primary me-1" onClick={() => {
                                        setFichaComposicao(ficha);
                                        setPopUpComposicaoFamiliarForm(true);
                                      }}>
                                        <i className="mdi mdi-grease-pencil d-block font-size"></i>
                                      </button>

                                      <button type="button" className="btn btn-danger" onClick={() => {
                                        let tempComposicao = aluno.ficha_composicao.filter(composicao => composicao.key !== ficha.key); 
                                        setAluno({ ...aluno, ficha_composicao: tempComposicao });
                                      }}>
                                        <i className="mdi mdi-trash-can d-block font-size"></i>
                                      </button>
                                    </td> 
                                  </tr>
                                })}

                              </tbody>  
                                
                                


                                   
                                       
                            </table>
                            
                            :
                            <p className="text-center">Não há composição, adicione um <a onClick={() => setPopUpComposicaoFamiliarForm(true)}>clicando aqui</a></p>
                            
                          }


                        </TabPane>
                        <TabPane tabId={5}>
                          <Row>
                            <Col >
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                  Irmãos na casa São Jose
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-cardno-input12"
                                  placeholder="Irmãos na casa..."
                                  onBlur={(e) => setAluno({ ...aluno, ficha_adicionais: { ...aluno.ficha_adicionais, irmaos: e.target.value } })}
                                />
                              </div>

                            </Col>
                            <Col >
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                  Restrição familiar
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-cardno-input12"
                                  placeholder="Restrição familiar..."
                                  onBlur={(e) => setAluno({ ...aluno, ficha_adicionais: { ...aluno.ficha_adicionais, restricao_familiar: e.target.value } })}
                                />
                              </div>

                            </Col>
                            <Col >
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                  Familia refugiada
                                </Label>
                                <select className="form-select" onChange={(e) => {
                                  if (e.target.value == "Sim")
                                    setAluno({ ...aluno, ficha_adicionais: { ...aluno.ficha_adicionais, familia_refugiada: 1 } })
                                  if (e.target.value == "Não")
                                    setAluno({ ...aluno, ficha_adicionais: { ...aluno.ficha_adicionais, familia_refugiada: 0, origem: null } })
 
                                }}>
                                  <option selected value="Não">Não</option>
                                  <option value="Sim">Sim</option>
                                </select>
                              </div>
                            </Col>
                            {parseBoolean(aluno.ficha_adicionais.familia_refugiada) &&
                              <Col>
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Origem
                                  </Label>

                                  <Input
                                    id="basicpill-address-input1"
                                    className="form-control"
                                    rows="1"
                                    placeholder="Origem..."
                                    onBlur={(e) => setAluno({ ...aluno, ficha_adicionais: { ...aluno.ficha_adicionais, origem: e.target.value } })}

                                  />
                                </div>
                              </Col>}



                            <Col >
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                  Observações
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-cardno-input12"
                                  placeholder="Observações..."
                                  onBlur={(e) => setAluno({ ...aluno, ficha_adicionais: { ...aluno.ficha_adicionais, observacoes: e.target.value } })}
                                />
                              </div>

                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={6}>
                          <Row>
                            <Col >
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                  Tem Cadastro único
                                </Label>
                                <select className="form-select" onChange={(e) => {
                                  if (e.target.value == "Sim")
                                    setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, cadastro_unico: "Sim" } })
                                  if (e.target.value == "Não")
                                    setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, cadastro_unico: "Não", qual_cras: null } })

                                }}>
                                  <option selected value="Não">Não</option>
                                  <option value="Sim">Sim</option>

                                </select>
                              </div>

                            </Col>
                            {parseBoolean(aluno.ficha_socio.cadastro_unico) &&
                              <Col>
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Qual CRAS
                                  </Label>

                                  <Input
                                    id="basicpill-address-input1"
                                    className="form-control"
                                    rows="1"
                                    placeholder="Qual CRAS..."
                                    onBlur={(e) => setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, qual_cras: e.target.value } })}
                                  />
                                </div>
                              </Col>}
                            <Col >
                              <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                  Benefício Socioassistencial
                                </Label>
                                <select className="form-select" onChange={(e) => {

                                  if (e.target.value == "Sim")
                                    return setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_qual: "Auxilio Brasil", beneficio_outros: null, valor_pensao: null, beneficio_socio: "Sim" } })
                                  if (e.target.value == "Não")
                                    return setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_qual: null, beneficio_outros: null, valor_pensao: null, beneficio_socio: "Não" } })
                                  //setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_socio: e.target.value } })
                                }}>
                                  <option selected value="Não">Não</option>
                                  <option value="Sim">Sim</option>

                                </select>
                              </div>

                            </Col>

                            {parseBoolean(aluno.ficha_socio.beneficio_socio) &&


                              <Col>

                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Qual benefício
                                  </Label>



                                  <select className="form-select" onChange={(e) => {


                                    if (e.target.value.includes("Pensao"))
                                      return setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_qual: e.target.value, beneficio_outros: null } })

                                    if (e.target.value.includes("Outros"))
                                      return setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_qual: e.target.value, valor_pensao: null } })

                                    if (e.target.value.includes("Bolsa Família"))
                                    return setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_qual: e.target.value, valor_pensao: null, beneficio_outros: null } })


                                    setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_qual: e.target.value, beneficio_outros: null, valor_pensao: null } })
                                  }}>
                                    {beneficios.map((beneficio_qual, index) => {
                                      return <option selected={aluno.ficha_socio.beneficio_qual == beneficio_qual} key={index} value={beneficio_qual}>{beneficio_qual}</option>
                                    })}

                                  </select>
                                </div>
                              </Col>}
                            {(aluno.ficha_socio.beneficio_qual == "Outros") &&
                              <Col>
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Qual
                                  </Label>

                                  <Input
                                    id="basicpill-address-input1"
                                    className="form-control"
                                    rows="1"
                                    placeholder="Qual benefício..."
                                    onBlur={(e) => setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_outros: e.target.value } })}
                                  />

                                </div>
                              </Col>}
                            {(aluno.ficha_socio.beneficio_qual == "Pensao") &&
                              <Col>
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Valor
                                  </Label>

                                  <Input
                                    id="basicpill-address-input1"
                                    className="form-control"
                                    rows="1"
                                    type="number"
                                    placeholder="Valor..."
                                    onBlur={(e) => setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, valor_pensao: e.target.value } })}
                                  />

                                </div>
                              </Col>}
                              {(aluno.ficha_socio.beneficio_qual == "Bolsa Família") &&
                              <Col>
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Numero do cartão
                                  </Label>

                                  <Input
                                    id="basicpill-address-input1"
                                    className="form-control"
                                    rows="1"
                                    placeholder=" Numero do cartão..."
                                    type="number"
                                    onBlur={(e) => setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_numero_cartao: e.target.value } })}
                                  />

                                </div>
                              </Col>}
                            <Row>
                              <Col >
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Atendimento
                                  </Label>
                                  <select className="form-select" onChange={(e) => {

                                    if (e.target.value == "Não foi atendido(a)")
                                      return setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, foi_atendido: "Não foi atendido(a)", motivo_atendimento: null } })

                                    setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, foi_atendido: e.target.value } })
                                  }}>
                                    <option value="Conselho Tutelar">Conselho Tutelar </option>
                                    <option value="CREAS/PAEFI">CREAS/PAEFI</option>
                                    <option value="CAPSi">CAPSi</option>
                                    <option selected value="Não foi atendido(a)">Não foi atendida</option>
                                  </select>
                                </div>

                              </Col>
                              {(aluno.ficha_socio.foi_atendido != "Não foi atendido(a)") &&
                                <Col >
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      Motivo do atendimento
                                    </Label>
                                    <Input
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      rows="1"
                                      placeholder="Motivo do atendimento..."
                                      onBlur={(e) => setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, motivo_atendimento: e.target.value } })}
                                    />

                                  </div>

                                </Col>}
                            </Row>

                          </Row>
                        </TabPane>

                      </TabContent>
                    </div>
                                   
                    <div className="text-end d-block">
                      <button className="btn btn-primary waves-effect waves-light btn btn-primary" onClick={handleSubmit}>
                        Salvar
                      </button>

                    </div>
                    
                  </div>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
      
    </React.Fragment>
  )
}

export default AlunoWizard
