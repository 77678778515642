import React from "react";
import { Spinner } from "reactstrap";

const Loading = () => {

  return (
    <div className="loading">
      <div>
        <Spinner className="ms-2" color="primary" />
        <p>Carregando...</p>
      </div>
    </div>
  )

}

export default Loading 