// src/components/filter.
import React from "react";

//import components
import { useState, useEffect } from "react";
import { get, del } from "helpers/api_helper";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import Loading from "components/loading";




function ListaDeAlunos() {
  const [alunos, setAlunos] = useState([]);
  const [loadingFicha, setLoadingFicha] = useState(true);
  const [listaAlunos, setListaAlunos] = useState([]);
  const [loadingCreate, setLoadingCreate] = useState(false)
  const getAlunos = async () => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    const response = await get("/api/aluno", {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    });

    setAlunos(response);
    setLoadingFicha(false);
    
  };
  const delAlunos = async (id) => {
    setLoadingCreate(true)
    const user = JSON.parse(localStorage.getItem("authUser"));
    const response = await del(`/api/aluno/${id}`, {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    });
    setLoadingCreate(false) 
  };

  useEffect(() => {
    getAlunos();
  }, []);

  document.title = "Alunos | CSJ";

  return (
    <div className="page-content">
      <div className="container-fluid">
        {loadingFicha && (
          <Loading />
        )}
        {loadingCreate &&
        <Loading />
      }
        <table className="table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Data de Nascimento</th>
              <th>Cpf</th>
              <th></th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {alunos.map((ficha, index) => {
              return (
                <tr key={index}>
                  <td>{ficha.nome_completo}</td>
                  <td>{ficha.data_nascimento}</td>
                  <td>{ficha.cpf}</td>

                  <td className="text-end">
                    <Link to={`/aluno/${ficha.id}`} className="btn btn-info">
                      <i className="mdi mdi-grease-pencil d-block font-size"></i>
                    </Link>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        setListaAlunos(ficha)
                        let listaAlunos = alunos.filter(fichas => fichas.id !== ficha.id);
                        setAlunos( listaAlunos );
                        delAlunos(ficha.id)
                      }} 
                    >
                      <i className="mdi mdi-trash-can d-block font-size"></i>
                    </button>
                    <Link to={`/aluno/imprimir/${ficha.id}`} className="btn btn-success">
                      <i className="mdi mdi-printer d-block font-size"></i>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListaDeAlunos;