import axios from "axios";

//pass new generated access token here
const token = JSON.parse(localStorage.getItem("authUser"))?.access_token;
//const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYjA3MjJkNTZiZThjMzBiODExY2NkM2RkZjBhZjM4OTJkYzY1NWE4ODYyOWU3MTY0Yjc1YjVmN2Q4NjA1NjRhMmQxZGM2MjYxOTg2MzIzY2YiLCJpYXQiOjE2OTUwMDQ5MjEuODY0NDI2LCJuYmYiOjE2OTUwMDQ5MjEuODY0NDMsImV4cCI6MTY5NjMwMDkyMC44NzgyNjksInN1YiI6IjEiLCJzY29wZXMiOltdfQ.SOyLZIVKEq0mOBsvU8AMhXz_tVsdUPIgslm124vndz5N_QGkdkQ-jTt3P7Cw2wkjiTckSNqG5Ann3j-kCVyVSQMrkHTs75htYP2sLegTRwxqRg2FRDg3xRPl5QY0gKlr_VewDBiIoutuhJUT5JaEUFNSub2Ua3xE9vL0NYvTBs7x4BHzYAJfSCoxCf0PcH9DLaDCbhXms3rKzRsf3cph2CfL3U8E0CFlv69Xv28fvlwGrZqUsWw7mqjpVGwXLEybB8mr2z9kYwi5arfbwbVadoMFp_0jpYW5K4Mx5Ef3Jo5V7F91tvPyZKbNoD3HO_OKx-al0ttFxi8Zn_fwhevJ3vRfbL05YEmrRvJxzF90vMjUJR3P5EjvpZjUKbqHYp5Ubue86eGdXQ78djnIudE5BXQTkmGMqvIup5rIagbZQgnUnXCjdOwhHKiBqg1rBrgZsISaTw_P6jQj-a30-bIEPkQioP-D-49U8FOoDpmdTUg2LGhDTZPYlhbgp5QK2UX-xiudGdhpvz_o_EczeBuF0ztrcMqCFRqa7O_mEMlVPQ21FWW05Ak4QLY3DG9j7r5ALQFucCvQnaHfxmoVYMT1-LPUo1KVozcOfRThYhYlXuCy77oZMITWETps1Hq2MyhjPczeovqfC7Jj5S-PCOqQC5wyY70tfJ2SY6sRRsYD_wk"

//apply base url for axios
const API_URL = "https://api.casasaojosefloripa.org.br";
//const API_URL = "http://localhost:8085";

const axiosApi = axios.create({
  baseURL: API_URL, 
});

axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
