import React, { useState } from "react"
import {
    Col,
    Input,
    Label,
    Row
} from "reactstrap"

const ComposicaoFamiliarForm = (props) => {

    console.log(props);
    const [composicao, setComposicao] = useState(props.fichaComposicao);

    const pushComposicao = () => {
        props.addComposicao(composicao);
    }
    return (
        <>
            <Row>
                <Col >
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            Genitor
                        </Label>
                        <select className="form-select" onChange={(e) => setComposicao({ ...composicao, genitor: e.target.value })}>
                            <option selected={composicao.genitor === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                            <option selected={composicao.genitor === "Não" ? "selected" : ""} value="Não">Não</option>
                        </select>
                    </div>
                </Col>
                <Col >
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            Grau Parentesco
                        </Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="basicpill-cardno-input12"
                            placeholder="Grau parentesco..."
                            defaultValue={composicao.grau_parentesco}
                            onBlur={(e) => setComposicao({ ...composicao, grau_parentesco: e.target.value })}


                        />
                    </div>
                </Col>
                <Col >
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            Nome
                        </Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="basicpill-cardno-input12"
                            placeholder="Nome..."
                            defaultValue={composicao.nome}
                            onBlur={(e) => setComposicao({ ...composicao, nome: e.target.value })}

                        />
                    </div>
                </Col>
                <Col >
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            Data nascimento
                        </Label>
                        <Input
                            className="form-control"
                            type="date"
                            defaultValue={composicao.data_nascimento}
                            id="example-date-input"
                            onBlur={(e) => setComposicao({ ...composicao, data_nascimento: e.target.value })}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col >
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            CPF
                        </Label>
                        <Input
                            type="number"
                            className="form-control"
                            id="basicpill-cardno-input12"
                            placeholder="CPF..."
                            defaultValue={composicao.cpf}
                            onBlur={(e) => setComposicao({ ...composicao, cpf: e.target.value })}

                        />
                    </div>
                </Col>
                <Col >
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            RG
                        </Label>
                        <Input
                            type="number"
                            className="form-control"
                            id="basicpill-cardno-input12"
                            placeholder="RG..."
                            defaultValue={composicao.rg}
                            onBlur={(e) => setComposicao({ ...composicao, rg: e.target.value })}

                        />
                    </div>
                </Col>
                <Col >
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            Naturalidade
                        </Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="basicpill-cardno-input12"
                            placeholder="Naturalidade..."
                            defaultValue={composicao.naturalidade}
                            onBlur={(e) => setComposicao({ ...composicao, naturalidade: e.target.value })}

                        />
                    </div>
                </Col>
                <Col >
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            Raça/Cor
                        </Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="basicpill-cardno-input12"
                            placeholder="Raça/Cor..."
                            defaultValue={composicao.raca_cor}
                            onBlur={(e) => setComposicao({ ...composicao, raca_cor: e.target.value })}

                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col >
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            Escolaridade
                        </Label>
                        <select className="form-select" defaultValue={composicao.escolaridade} onChange={(e) => setComposicao({ ...composicao, escolaridade: e.target.value })}>

                            <option value="Não Alfabetizado ">Não Alfabetizado </option>
                            <option value="Séries Iniciais 1º ao 5º">Séries Iniciais 1º ao 5º</option>
                            <option value="Ensino Fundamental Incompleto 6º ao 9º">Ensino Fundamental Incompleto 6º ao 9º</option>
                            <option value="Ensino Médio Incompleto">Ensino Médio Incompleto</option>
                            <option value="Ensino Médio Completo">Ensino Médio Completo</option>
                            <option value="Ensino Superior Completo">Ensino Superior Completo</option>
                            <option value="Ensino Superior Incompleto">Ensino Superior Incompleto</option>
                            <option value="Ensino Superior Cursando">Ensino Superior Cursando</option>

                        </select>
                    </div>

                </Col>
                <Col >
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            Condição de trabalho
                        </Label>
                        <select className="form-select" defaultValue={composicao.condicao_trabalho} onChange={(e) => {
                            if (e.target.value == "  Desempregado")
                                setComposicao({ ...composicao, funcao: null, condicao_trabalho: "Desempregado" })
                            if (e.target.value == "Não Trabalha")
                                setComposicao({ ...composicao, funcao: null, condicao_trabalho: "Não Trabalha" })
                            if (e.target.value == "Autônomo")
                                setComposicao({ ...composicao, condicao_trabalho: "Autônomo" })
                            if (e.target.value == "CLT")
                                setComposicao({ ...composicao, condicao_trabalho: "CLT" })
                            if (e.target.value == "Trabalha")
                                setComposicao({ ...composicao, condicao_trabalho: "Trabalha" })


                        }}>

                            <option value="Trabalha">Trabalha</option>
                            <option value="CLT">CLT</option>
                            <option value="Autônomo">Autônomo</option>
                            <option value="Não Trabalha">Não Trabalha</option>
                            <option value="Desempregado">Desempregado</option>
                        </select>
                    </div>

                </Col>
                {(composicao.condicao_trabalho != "Não Trabalha" && composicao.condicao_trabalho != "Desempregado") &&

                    <Col>
                        <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                                Função
                            </Label>

                            <Input
                                id="basicpill-address-input1"
                                className="form-control"
                                rows="1"
                                placeholder="Função..."
                                defaultValue={composicao.funcao}
                                onBlur={(e) => setComposicao({ ...composicao, funcao: e.target.value })}
                            />

                        </div>
                    </Col>}
                <Col>
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            Renda
                        </Label>

                        <Input
                            id="basicpill-address-input1"
                            className="form-control"
                            rows="1"
                            type="number"
                            placeholder="Renda..."
                            defaultValue={composicao.renda}
                            onBlur={(e) => setComposicao({ ...composicao, renda: e.target.value })}
                        />

                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            Interese em Curso
                        </Label>

                        <Input
                            id="basicpill-address-input1"
                            className="form-control"
                            rows="1"
                            placeholder="Interese em Curso..."
                            defaultValue={composicao.interese_curso}
                            onBlur={(e) => setComposicao({ ...composicao, interese_curso: e.target.value })}
                        />

                    </div>
                </Col>
                <Col>
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            DDD
                        </Label>

                        <Input
                            id="basicpill-address-input1"
                            className="form-control"
                            rows="1"
                            type="number"
                            placeholder="DDD..."
                            defaultValue={composicao.DDD}
                            onBlur={(e) => setComposicao({ ...composicao, DDD: e.target.value })}
                        />

                    </div>
                </Col>
                <Col>
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            Telefones
                        </Label>

                        <Input
                            id="basicpill-address-input1"
                            className="form-control"
                            rows="1"
                            type="number"
                            placeholder="Telefone..."
                            defaultValue={composicao.telefone}
                            onBlur={(e) => setComposicao({ ...composicao, telefone: e.target.value })}
                        />

                    </div>
                </Col>

                <Col>
                    <div className="mb-3">
                        <Label for="basicpill-lastname-input2">
                            Tem algum problema de saúde
                        </Label>

                        <Input
                            id="basicpill-address-input1"
                            className="form-control"
                            rows="1"
                            placeholder="Problema de saúde..."
                            defaultValue={composicao.problema_saude}
                            onBlur={(e) => setComposicao({ ...composicao, problema_saude: e.target.value })}
                        />

                    </div>
                </Col>
            </Row>

            <Row>
                <Col className={"mb-3"}>
                    <Label for="basicpill-lastname-input2">
                        Observações
                    </Label>

                    <Input
                        id="basicpill-address-input1"
                        className="form-control"
                        rows="1"
                        placeholder="Observações..."
                        defaultValue={composicao.observacoes}
                        onBlur={(e) => setComposicao({ ...composicao, observacoes: e.target.value })}
                    />
                </Col>
            </Row>
                
            <div className="row">
                <div className="col text-end">
                    <button className="btn btn-primary" onClick={pushComposicao}>Salvar</button>
                </div>
            </div>
        </>
    )
}

export default ComposicaoFamiliarForm