import React, { useEffect, useState } from "react"
import {
    Col,
    Form,
    Input,
    Label,
    Row
} from "reactstrap"

import { get } from "helpers/api_helper"
import { useParams } from "react-router-dom";
import Loading from "components/loading"

function ImprimirAlunos() {
    const params = useParams();

    const [aluno, setAluno] = useState({
        ficha_saude: {},
        ficha_moradia: {},
        ficha_composicao: [],
        ficha_adicionais: {},
        ficha_socio: {}
    });
    const [condicao, setCondicao] = useState([
        "Própria",
        "Cedida",
        "Alugada"
    ])

    const [escolas, setEscolas] = useState([
        "EBM José Jacinto Cardoso",
        "EEB Simão José Hess",
        "EEB Hilda Teodoro Vieira",
        "EEB Beatriz de Souza Britto",
        "Colégio de Aplicação UFSC",
        "EEB Pe. João Alfredo Rohr",
        "Colégio Tradição",
        "Instituto Estadual de Educação",
        "Outros"])

    const [beneficios, setBenefocios] = useState([
        "Bolsa Família",
        "Auxilio Brasil",
        "BPC",
        "Cartão Social",
        "Pensao",
        "Outros"
    ])

    const [loadingFicha, setLoadingFicha] = useState(true);

    const getAlunos = async () => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        const response = await get(`/api/aluno/${params.id}`, {
            headers: {
                Authorization: `Bearer ${user.access_token}`,
            },
        });

        setAluno(response);
        setLoadingFicha(false);

        setTimeout(() => {
            window.print();
            window.onfocus = window.location = `/aluno/${response.id}`
        }, 2000);
    };

    useEffect(() => {
        getAlunos();
    }, []);


    const parseBoolean = (value) => {
        if (typeof value == "string")
            return value.toLocaleLowerCase() == "sim"

        return value === 1;
    }

    document.title = "Alunos | CSJ";

    return (
        <div className="page-content">
            <div className="container-fluid">
                {loadingFicha && (
                    <Loading />
                )}

                <Form>
                    <Row>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Nº da Matrícula
                                </Label>
                                <Input

                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Nº da Matrícula..."
                                    defaultValue={aluno.n_matricula}
                                    onBlur={(e) => setAluno({ ...aluno, n_matricula: e.target.value })}
                                />
                            </div>

                        </Col>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Data de ingresso
                                </Label>
                                <Input

                                    className="form-control"
                                    type="date"
                                    defaultValue={aluno.data_do_ingresso}
                                    id="example-date-input"
                                    onBlur={(e) => setAluno({ ...aluno, data_do_ingresso: e.target.value })}
                                />
                            </div>

                        </Col>

                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Data de nascimento
                                </Label>
                                <Input

                                    className="form-control"
                                    type="date"
                                    defaultValue={aluno.data_nascimento}
                                    id="example-date-input"
                                    onBlur={(e) => setAluno({ ...aluno, data_nascimento: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col >
                            <div className="col-sm-auto">
                                <Label for="basicpill-lastname-input2">
                                    Sexo
                                </Label>
                                <select className="form-select" onChange={(e) => setAluno({ ...aluno, sexo: e.target.value })}>

                                    <option selected={aluno.sexo === "Masculino" ? "selected" : ""} value="Masculino">Masculino</option>
                                    <option selected={aluno.sexo === "Feminino" ? "selected" : ""} value="Feminino">Feminino</option>
                                </select>
                            </div>
                        </Col>
                        <Col >
                            <div className="col-sm-auto">
                                <Label for="basicpill-lastname-input2">
                                    Raça/Cor
                                </Label>
                                <select className="form-select" onChange={(e) => setAluno({ ...aluno, raca_cor: e.target.value })}>

                                    <option selected={aluno.raca_cor === "Branca" ? "selected" : ""} value="Branca">Branca</option>
                                    <option selected={aluno.raca_cor === "Parda" ? "selected" : ""} value="Parda">Parda</option>
                                    <option selected={aluno.raca_cor === "Negra" ? "selected" : ""} value="Negra">Negra</option>
                                    <option selected={aluno.raca_cor === "Indígena" ? "selected" : ""} value="Indígena">Indígena</option>
                                    <option selected={aluno.raca_cor === "Amarela" ? "selected" : ""} value="Amarela">Amarela</option>
                                    <option selected={aluno.raca_cor === "Não informado" ? "selected" : ""} value="Não informado">Não informado</option>
                                </select>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-phoneno-input3">
                                    Nome completo
                                </Label>
                                <Input

                                    type="text"
                                    className="form-control"
                                    id="basicpill-phoneno-input3"
                                    placeholder="Nome completo..."
                                    defaultValue={aluno.nome_completo}
                                    onBlur={(e) => setAluno({ ...aluno, nome_completo: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                    Nome social
                                </Label>
                                <Input

                                    type="text"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="Nome social..."
                                    defaultValue={aluno.nome_social}
                                    onBlur={(e) => setAluno({ ...aluno, nome_social: e.target.value })}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                    Naturalidade
                                </Label>
                                <Input

                                    type="text"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="Naturalidade..."
                                    defaultValue={aluno.naturalidade}
                                    onBlur={(e) => setAluno({ ...aluno, naturalidade: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                    CPF
                                </Label>
                                <Input

                                    type="text"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="CPF..."
                                    defaultValue={aluno.cpf}

                                    onBlur={(e) => setAluno({ ...aluno, cpf: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                    RG
                                </Label>
                                <Input

                                    type="text"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="RG..."
                                    defaultValue={aluno.rg}
                                    onBlur={(e) => setAluno({ ...aluno, rg: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-email-input4">
                                    NIS
                                </Label>
                                <Input

                                    type="text"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="NIS..."
                                    defaultValue={aluno.nis}
                                    onBlur={(e) => setAluno({ ...aluno, nis: e.target.value })}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Escolaridade
                                </Label>
                                <select className="form-select" onChange={(e) => setAluno({ ...aluno, escolaridade: e.target.value })}>

                                    <option selected={aluno.escolaridade === "1°EF" ? "selected" : ""} value="1°EF">1°EF</option>
                                    <option selected={aluno.escolaridade === "2°EF" ? "selected" : ""} value="2°EF">2°EF</option>
                                    <option selected={aluno.escolaridade === "3°EF" ? "selected" : ""} value="3°EF">3°EF</option>
                                    <option selected={aluno.escolaridade === "4°EF" ? "selected" : ""} value="4°EF">4°EF</option>
                                    <option selected={aluno.escolaridade === "5°EF" ? "selected" : ""} value="5°EF">5°EF</option>
                                    <option selected={aluno.escolaridade === "6°EF" ? "selected" : ""} value="6°EF">6°EF</option>
                                    <option selected={aluno.escolaridade === "7°EF" ? "selected" : ""} value="7°EF">7°EF</option>
                                    <option selected={aluno.escolaridade === "8°EF" ? "selected" : ""} value="8°EF">8°EF</option>
                                    <option selected={aluno.escolaridade === "9°EF" ? "selected" : ""} value="9°EF">9°EF</option>
                                    <option selected={aluno.escolaridade === "1°EM" ? "selected" : ""} value="1°EM">1°EM</option>
                                    <option selected={aluno.escolaridade === "2°EM" ? "selected" : ""} value="2°EM">2°EM</option>
                                    <option selected={aluno.escolaridade === "3°EM" ? "selected" : ""} value="3°EM">3°EM</option>

                                </select>
                            </div>

                        </Col>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Turno
                                </Label>
                                <select className="form-select" onChange={(e) => setAluno({ ...aluno, turno: e.target.value })}>

                                    <option selected={aluno.turno === "Matutino" ? "selected" : ""} value="Matutino">Matutino</option>
                                    <option selected={aluno.turno === "Vespertino" ? "selected" : ""} value="Vespertino">Vespertino</option>
                                    <option selected={aluno.turno === "Noturno" ? "selected" : ""} value="Noturno">Noturno</option>
                                </select>
                            </div>

                        </Col>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Escola
                                </Label>
                                <select className="form-select" onChange={(e) => setAluno({ ...aluno, escola: e.target.value })}>
                                    {escolas.map((escola, index) => {
                                        return <option selected={aluno.escola == escola} key={index} value={escola}>{escola}</option>
                                    })}
                                </select>
                            </div>

                        </Col>
                        {(!escolas.includes(aluno.escola) || aluno.escola == "Outros") &&
                            <Col>
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Qual
                                    </Label>

                                    <Input

                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        placeholder="Nome da escola..."
                                        defaultValue={aluno.escola}
                                        onBlur={(e) => setAluno({ ...aluno, escola: e.target.value })}
                                    />

                                </div>
                            </Col>}
                    </Row>
                    <Row>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Reprovou
                                </Label>
                                <select className="form-select" onChange={(e) => setAluno({ ...aluno, reprovou: e.target.value })}>

                                    <option selected={aluno.reprovou === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                    <option selected={aluno.reprovou === "Não" ? "selected" : ""} value="Não">Não</option>
                                </select>
                            </div>

                        </Col>
                        {parseBoolean(aluno.reprovou) &&
                            <Col>
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Aonde reprovou
                                    </Label>

                                    <Input

                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        placeholder="Aonde reprovou..."
                                        defaultValue={aluno.aonde_reprovou}
                                        onBlur={(e) => setAluno({ ...aluno, aonde_reprovou: e.target.value })}
                                    />
                                </div>
                            </Col>}

                        <Col>
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Participa de algum outro projeto
                                </Label>
                                <select className="form-select" onChange={(e) => setAluno({ ...aluno, participa_outro_projeto: e.target.value })}>

                                    <option selected={aluno.participa_outro_projeto === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                    <option selected={aluno.participa_outro_projeto === "Não" ? "selected" : ""} value="Não">Não</option>
                                </select>
                            </div>


                        </Col>
                        {parseBoolean(aluno.participa_outro_projeto) &&
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Quais dias
                                    </Label>

                                    <Input

                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        placeholder="Quais dias..."
                                        defaultValue={aluno.dias_projeto}
                                        onBlur={(e) => setAluno({ ...aluno, dias_projeto: e.target.value })}
                                    />
                                </div>
                            </Col>}
                    </Row>
                    <Row>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Forma de acesso
                                </Label>
                                <select className="form-select" onChange={(e) => setAluno({ ...aluno, forma_acesso: e.target.value })}>
                                    <option selected={aluno.forma_acesso === "Pela área da Educação" ? "selected" : ""} value="Pela área da Educação">Pela área da Educação</option>
                                    <option selected={aluno.forma_acesso === "Pela área da Saúde" ? "selected" : ""} value="Pela área da Saúde">Pela área da Saúde</option>
                                    <option selected={aluno.forma_acesso === "Pelo Conselho Tutelar" ? "selected" : ""} value="Pelo Conselho Tutelar">Pelo Conselho Tutelar</option>
                                    <option selected={aluno.forma_acesso === "Pelo Poder Judiciário" ? "selected" : ""} value="Pelo Poder Judiciário">Pelo Poder Judiciário</option>
                                    <option selected={aluno.forma_acesso === "Pelo Sistema de Garantia de Direitos" ? "selected" : ""} value="Pelo Sistema de Garantia de Direitos">Pelo Sistema de Garantia de Direitos</option>
                                    <option selected={aluno.forma_acesso === "Busca Ativa da Equipe" ? "selected" : ""} value="Busca Ativa da Equipe">Busca Ativa da Equipe</option>
                                    <option selected={aluno.forma_acesso === "Demanda Espontâne" ? "selected" : ""} value="Demanda Espontâne">Demanda Espontânea</option>
                                    <option selected={aluno.forma_acesso === "Outras Políticas Setoriais" ? "selected" : ""} value="Outras Políticas Setoriais">Outras Políticas Setoriais</option>
                                    <option selected={aluno.forma_acesso === "Serviços da Proteção Social Básica" ? "selected" : ""} value="Serviços da Proteção Social Básica">Serviços da Proteção Social Básica</option>
                                    <option selected={aluno.forma_acesso === "Serviços da Proteção Social Especial" ? "selected" : ""} value="Serviços da Proteção Social Especial">Serviços da Proteção Social Especial</option>
                                </select>

                            </div>



                        </Col>
                    </Row>
                </Form>

                <div className="d-print-block">
                    <Form>
                        <Row>
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Possui alguma Deficiência
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                        if (e.target.value == "Não")
                                            setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, deficiencia: "Não", deficiencia_qual: null } })
                                        if (e.target.value == "Sim")
                                            setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, deficiencia: "Sim" } })
                                    }}>

                                        <option selected={aluno.ficha_saude.deficiencia === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                        <option selected={aluno.ficha_saude.deficiencia === "Não" ? "selected" : ""} value="Não">Não</option>
                                    </select>
                                </div>

                            </Col>
                            {parseBoolean(aluno.ficha_saude.deficiencia) &&
                                <>
                                    <Col>
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Qual
                                            </Label>

                                            <Input

                                                id="basicpill-address-input1"
                                                className="form-control"
                                                rows="1"
                                                placeholder="Qual deficiência..."
                                                defaultValue={aluno.ficha_saude.deficiencia_qual}
                                                onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, deficiencia_qual: e.target.value } })}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Possui laudo técnico
                                            </Label>

                                            <select className="form-select" onChange={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, deficiencia_laudo: e.target.value } })}>

                                                <option selected={aluno.ficha_saude.deficiencia_laudo === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                                <option selected={aluno.ficha_saude.deficiencia_laudo === "Não" ? "selected" : ""} value="Não">Não</option>
                                            </select>
                                        </div>
                                    </Col>
                                </>
                            }
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Tem Alergias / Restrição alimentar
                                    </Label>
                                    <select className="form-select" onChange={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, alergia_restricao: e.target.value } })}>

                                        <option selected={aluno.ficha_saude.alergia_restricao === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                        <option selected={aluno.ficha_saude.alergia_restricao === "Não" ? "selected" : ""} value="Não">Não</option>
                                    </select>
                                </div>

                            </Col>
                            {parseBoolean(aluno.ficha_saude.alergia_restricao) &&
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-lastname-input2">
                                            Quais
                                        </Label>

                                        <Input

                                            id="basicpill-address-input1"
                                            className="form-control"
                                            rows="1"
                                            placeholder="Alergias/Restrição..."
                                            defaultValue={aluno.ficha_saude.alergia_qual}
                                            onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, alergia_qual: e.target.value } })}
                                        />
                                    </div>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Usa medicamentos de uso contínuo
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                        if (e.target.value == "Sim")
                                            setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, usa_medicamentos: 1 } })
                                        if (e.target.value == "Não")
                                            setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, usa_medicamentos: 0, medicamentos_qual: null } })
                                    }}
                                    >
                                        <option selected={aluno.ficha_saude.usa_medicamentos === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                        <option selected={aluno.ficha_saude.usa_medicamentos === "Não" ? "selected" : ""} value="Não">Não</option>
                                    </select>
                                </div>

                            </Col>
                            {parseBoolean(aluno.ficha_saude.usa_medicamentos) &&
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-lastname-input2">
                                            Quais
                                        </Label>

                                        <Input

                                            id="basicpill-address-input1"
                                            className="form-control"
                                            rows="1"
                                            placeholder="Quais medicamentos..."
                                            defaultValue={aluno.ficha_saude.medicamentos_qual}
                                            onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, medicamentos_qual: e.target.value } })}
                                        />
                                    </div>
                                </Col>}
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Faz tratamento
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                        if (e.target.value == "Sim")
                                            setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, faz_tratamento: 1 } })
                                        if (e.target.value == "Não")
                                            setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, faz_tratamento: 0, tratamento_qual: null } })

                                    }}>

                                        <option selected={aluno.ficha_saude.faz_tratamento === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                        <option selected={aluno.ficha_saude.faz_tratamento === "Não" ? "selected" : ""} value="Não">Não</option>
                                    </select>
                                </div>

                            </Col>
                            {parseBoolean(aluno.ficha_saude.faz_tratamento) &&
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-lastname-input2">
                                            Qual
                                        </Label>

                                        <Input

                                            id="basicpill-address-input1"
                                            className="form-control"
                                            rows="1"
                                            placeholder="Qual tratamento..."
                                            defaultValue={aluno.ficha_saude.tratamento_qual}
                                            onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, tratamento_qual: e.target.value } })}

                                        />
                                    </div>
                                </Col>}
                        </Row>
                        <Row>
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Já fez acompanhamento fonoaudiológico
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                        if (e.target.value == "Sim")
                                            setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, faz_fono: 1 } })
                                        if (e.target.value == "Não")
                                            setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, faz_fono: 0, motivo_fono: null } })
                                    }}>

                                        <option selected={aluno.ficha_saude.faz_tratamento === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                        <option selected={aluno.ficha_saude.faz_tratamento === "Não" ? "selected" : ""} value="Não">Não</option>
                                    </select>
                                </div>

                            </Col>
                            {parseBoolean(aluno.ficha_saude.faz_fono) &&
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-lastname-input2">
                                            Qual motivo
                                        </Label>

                                        <Input

                                            id="basicpill-address-input1"
                                            className="form-control"
                                            rows="1"
                                            placeholder="Qual motivo..."
                                            defaultValue={aluno.ficha_saude.motivo_fono}
                                            onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, motivo_fono: e.target.value } })}
                                        />
                                    </div>
                                </Col>}
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Já fez acompanhamento psicológico
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                        if (e.target.value == "Sim")
                                            setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, faz_psicologico: 1 } })
                                        if (e.target.value == "Não")
                                            setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, faz_psicologico: 0, motivo_psicologico: null } })
                                    }}>

                                        <option selected={aluno.ficha_saude.faz_psicologico === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                        <option selected={aluno.ficha_saude.faz_psicologico === "Não" ? "selected" : ""} value="Não">Não</option>
                                    </select>
                                </div>

                            </Col>
                            {parseBoolean(aluno.ficha_saude.faz_psicologico) &&
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-lastname-input2">
                                            Qual motivo
                                        </Label>

                                        <Input

                                            id="basicpill-address-input1"
                                            className="form-control"
                                            rows="1"
                                            placeholder="Qual motivo..."
                                            defaultValue={aluno.ficha_saude.motivo_psicologico}
                                            onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, motivo_psicologico: e.target.value } })}
                                        />
                                    </div>
                                </Col>}
                        </Row>
                        <Row>
                            <Col>
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Centro de saúde que a família frequenta
                                    </Label>

                                    <Input

                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        placeholder="Qual centro..."
                                        defaultValue={aluno.ficha_saude.qual_centro_saude_familia}
                                        onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, qual_centro_saude_familia: e.target.value } })}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Nº do Cartão do SUS
                                    </Label>

                                    <Input

                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        placeholder="Cartão do SUS..."
                                        defaultValue={aluno.ficha_saude.n_cartao_sus}
                                        onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, n_cartao_sus: e.target.value } })}
                                    />
                                </div>
                            </Col>
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Já tomou vacina da COVID
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                        if (e.target.value == "Sim")
                                            setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, vacina_covid: 1 } })
                                        if (e.target.value == "Não")
                                            setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, vacina_covid: 0, n_vacinas_covid: null } })
                                    }}>

                                        <option selected={aluno.ficha_saude.vacina_covid === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                        <option selected={aluno.ficha_saude.vacina_covid === "Não" ? "selected" : ""} value="Não">Não</option>
                                    </select>
                                </div>

                            </Col>
                            {parseBoolean(aluno.ficha_saude.vacina_covid) &&
                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-lastname-input2">
                                            Numero de doses
                                        </Label>

                                        <Input

                                            id="basicpill-address-input1"
                                            className="form-control"
                                            rows="1"
                                            placeholder="Doses..."
                                            defaultValue={aluno.ficha_saude.n_vacinas_covid}
                                            onBlur={(e) => setAluno({ ...aluno, ficha_saude: { ...aluno.ficha_saude, n_vacinas_covid: e.target.value } })}
                                        />
                                    </div>
                                </Col>}
                        </Row>
                    </Form>
                </div>


                <div>
                    <Form>
                        <Row>
                            <Col>
                                <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                        Endereço
                                    </Label>
                                    <Input

                                        type="text"
                                        className="form-control"
                                        id="basicpill-namecard-input11"
                                        placeholder="Endereço..."
                                        defaultValue={aluno.ficha_moradia.endereco}
                                        onBlur={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, endereco: e.target.value } })}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                        Numero
                                    </Label>
                                    <Input

                                        type="text"
                                        className="form-control"
                                        id="basicpill-namecard-input11"
                                        placeholder="Numero..."
                                        defaultValue={aluno.ficha_moradia.endereco_numero}
                                        onBlur={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, endereco_numero: e.target.value } })}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                        CEP
                                    </Label>
                                    <Input

                                        type="text"
                                        className="form-control"
                                        id="basicpill-namecard-input11"
                                        placeholder="CEP..."
                                        onBlur={async (e) => {
                                            setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, cep: e.target.value } });
                                            await buscaCep(e.target.value);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">
                                        Bairro
                                    </Label>
                                    <Input

                                        type="text"
                                        className="form-control"
                                        id="basicpill-namecard-input11"
                                        placeholder="Bairro..."
                                        defaultValue={aluno.ficha_moradia.bairro}
                                        onBlur={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, bairro: e.target.value } })}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-cardno-input12">
                                        Ponto de referência
                                    </Label>
                                    <Input

                                        type="text"
                                        className="form-control"
                                        id="basicpill-cardno-input12"
                                        placeholder="Ponto de referência..."
                                        defaultValue={aluno.ficha_moradia.ponto_referencia}
                                        onBlur={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, ponto_referencia: e.target.value } })}
                                    />
                                </div>
                            </Col>
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Condições de moradia
                                    </Label>
                                    <select className="form-select" onChange={(e) => {
                                        if (e.target.value == "Cedida")
                                            setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_valor: null, moradia_condicao: "Cedida" } })
                                        if (e.target.value == "Própria")
                                            setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_valor: null, moradia_condicao: "Própria" } })
                                        if (e.target.value == "Alugada")
                                            setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_condicao: "Alugada" } })

                                    }}>

                                        {condicao.map((moradia_condicao, index) => {
                                            return <option selected={aluno.ficha_moradia.moradia_condicao == moradia_condicao} key={index} value={moradia_condicao}>{moradia_condicao}</option>
                                        })}
                                    </select>
                                </div>

                            </Col>
                            {(!condicao.includes(aluno.ficha_moradia.moradia_condicao) || aluno.ficha_moradia.moradia_condicao == "Alugada") &&


                                <Col>
                                    <div className="mb-3">
                                        <Label for="basicpill-lastname-input2">
                                            Valor do aluguel
                                        </Label>

                                        <Input

                                            id="basicpill-address-input1"
                                            className="form-control"
                                            rows="1"
                                            placeholder="Valor..."
                                            defaultValue={aluno.ficha_moradia.moradia_valor}
                                            onBlur={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_valor: e.target.value } })}

                                        />

                                    </div>
                                </Col>}
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Água encanada
                                    </Label>
                                    <select className="form-select" onChange={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_agua_encanada: e.target.value } })}>
                                        <option selected={aluno.ficha_moradia.moradia_agua_encanada === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                        <option selected={aluno.ficha_moradia.moradia_agua_encanada === "Não" ? "selected" : ""} value="Não">Não</option>

                                    </select>
                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Rede de esgoto
                                    </Label>
                                    <select className="form-select" onChange={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_rede_esgoto: e.target.value } })}>
                                        <option selected={aluno.ficha_moradia.moradia_rede_esgoto === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                        <option selected={aluno.ficha_moradia.moradia_rede_esgoto === "Não" ? "selected" : ""} value="Não">Não</option>

                                    </select>
                                </div>

                            </Col>
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Coleta de lixo
                                    </Label>
                                    <select className="form-select" onChange={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_coleta_lixo: e.target.value } })}>
                                        <option selected={aluno.ficha_moradia.moradia_coleta_lixo === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                        <option selected={aluno.ficha_moradia.moradia_coleta_lixo === "Não" ? "selected" : ""} value="Não">Não</option>

                                    </select>
                                </div>

                            </Col>
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Tipo de Construção
                                    </Label>
                                    <select className="form-select" onChange={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_tipo_construcao: e.target.value } })}>
                                        <option selected={aluno.ficha_moradia.moradia_tipo_construcao === "Alvenaria" ? "selected" : ""} value="Alvenaria">Alvenaria</option>
                                        <option selected={aluno.ficha_moradia.moradia_tipo_construcao === "Madeira" ? "selected" : ""} value="Madeira">Madeira</option>
                                        <option selected={aluno.ficha_moradia.moradia_tipo_construcao === "Mista" ? "selected" : ""} value="Mista">Mista</option>

                                    </select>
                                </div>

                            </Col>
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-cardno-input12">
                                        Quais Cômodos
                                    </Label>

                                    <Input

                                        type="text"
                                        className="form-control"
                                        id="basicpill-cardno-input12"
                                        placeholder="Quais cômodos..."
                                        defaultValue={aluno.ficha_moradia.moradia_quais_comodos}
                                        onBlur={(e) => setAluno({ ...aluno, ficha_moradia: { ...aluno.ficha_moradia, moradia_quais_comodos: e.target.value } })}

                                    />


                                </div>

                            </Col>
                        </Row>
                    </Form>
                </div>

                {aluno.ficha_composicao.length > 0 &&

                    <div>
                        
                        {aluno.ficha_composicao.map((composicao, index) => {
                            return <>
                                <Row>
                                    <Col >
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Genitor
                                            </Label>
                                            <select className="form-select">
                                                <option selected={composicao.genitor === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                                <option selected={composicao.genitor === "Não" ? "selected" : ""} value="Não">Não</option>
                                            </select>
                                        </div>
                                    </Col>
                                    <Col >
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Grau Parentesco
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="basicpill-cardno-input12"
                                                placeholder="Grau parentesco..."
                                                defaultValue={composicao.grau_parentesco}
                                            />
                                        </div>
                                    </Col>
                                    <Col >
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Nome
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="basicpill-cardno-input12"
                                                placeholder="Nome..."
                                                defaultValue={composicao.nome}
                                            />
                                        </div>
                                    </Col>
                                    <Col >
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Data nascimento
                                            </Label>
                                            <Input
                                                className="form-control"
                                                type="date"
                                                defaultValue={composicao.data_nascimento}
                                                id="example-date-input"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col >
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                CPF
                                            </Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="basicpill-cardno-input12"
                                                placeholder="CPF..."
                                                defaultValue={composicao.cpf}

                                            />
                                        </div>
                                    </Col>
                                    <Col >
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                RG
                                            </Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="basicpill-cardno-input12"
                                                placeholder="RG..."
                                                defaultValue={composicao.rg}
                                            />
                                        </div>
                                    </Col>
                                    <Col >
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Naturalidade
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="basicpill-cardno-input12"
                                                placeholder="Naturalidade..."
                                                defaultValue={composicao.naturalidade}
                                            />
                                        </div>
                                    </Col>
                                    <Col >
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Raça/Cor
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="basicpill-cardno-input12"
                                                placeholder="Raça/Cor..."
                                                defaultValue={composicao.raca_cor}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col >
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Escolaridade
                                            </Label>
                                            <select className="form-select" defaultValue={composicao.escolaridade}>

                                                <option value="Não Alfabetizado ">Não Alfabetizado </option>
                                                <option value="Séries Iniciais 1º ao 5º">Séries Iniciais 1º ao 5º</option>
                                                <option value="Ensino Fundamental Incompleto 6º ao 9º">Ensino Fundamental Incompleto 6º ao 9º</option>
                                                <option value="Ensino Médio Incompleto">Ensino Médio Incompleto</option>
                                                <option value="Ensino Médio Completo">Ensino Médio Completo</option>
                                                <option value="Ensino Superior Completo">Ensino Superior Completo</option>
                                                <option value="Ensino Superior Incompleto">Ensino Superior Incompleto</option>
                                                <option value="Ensino Superior Cursando">Ensino Superior Cursando</option>

                                            </select>
                                        </div>

                                    </Col>
                                    <Col >
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Condição de trabalho
                                            </Label>
                                            <select className="form-select" defaultValue={composicao.condicao_trabalho}>
                                                <option value="Trabalha">Trabalha</option>
                                                <option value="CLT">CLT</option>
                                                <option value="Autônomo">Autônomo</option>
                                                <option value="Não Trabalha">Não Trabalha</option>
                                                <option value="Desempregado">Desempregado</option>
                                            </select>
                                        </div>

                                    </Col>
                                    {(composicao.condicao_trabalho != "Não Trabalha" && composicao.condicao_trabalho != "Desempregado") &&

                                        <Col>
                                            <div className="mb-3">
                                                <Label for="basicpill-lastname-input2">
                                                    Função
                                                </Label>

                                                <Input
                                                    id="basicpill-address-input1"
                                                    className="form-control"
                                                    rows="1"
                                                    placeholder="Função..."
                                                    defaultValue={composicao.funcao}
                                                />

                                            </div>
                                        </Col>}
                                    <Col>
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Renda
                                            </Label>

                                            <Input
                                                id="basicpill-address-input1"
                                                className="form-control"
                                                rows="1"
                                                type="number"
                                                placeholder="Renda..."
                                                defaultValue={composicao.renda}
                                            />

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Interese em Curso
                                            </Label>

                                            <Input
                                                id="basicpill-address-input1"
                                                className="form-control"
                                                rows="1"
                                                placeholder="Interese em Curso..."
                                                defaultValue={composicao.interese_curso}
                                            />

                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                DDD
                                            </Label>

                                            <Input
                                                id="basicpill-address-input1"
                                                className="form-control"
                                                rows="1"
                                                type="number"
                                                placeholder="DDD..."
                                                defaultValue={composicao.DDD}
                                            />

                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Telefones
                                            </Label>

                                            <Input
                                                id="basicpill-address-input1"
                                                className="form-control"
                                                rows="1"
                                                type="number"
                                                placeholder="Telefone..."
                                                defaultValue={composicao.telefone}
                                            />

                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="mb-3">
                                            <Label for="basicpill-lastname-input2">
                                                Tem algum problema de saúde
                                            </Label>

                                            <Input
                                                id="basicpill-address-input1"
                                                className="form-control"
                                                rows="1"
                                                placeholder="Problema de saúde..."
                                                defaultValue={composicao.problema_saude}
                                            />

                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className={"mb-3"}>
                                        <Label for="basicpill-lastname-input2">
                                            Observações
                                        </Label>

                                        <Input
                                            id="basicpill-address-input1"
                                            className="form-control"
                                            rows="1"
                                            placeholder="Observações..."
                                            defaultValue={composicao.observacoes}
                                        />
                                    </Col>
                                </Row>
                            </>
                        })}
                    </div>
                }

                <Row>
                    <Col >
                        <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                                Irmãos na casa São Jose
                            </Label>
                            <Input

                                type="text"
                                className="form-control"
                                id="basicpill-cardno-input12"
                                placeholder="Irmãos na casa..."
                                defaultValue={aluno.ficha_adicionais.irmaos}
                                onBlur={(e) => setAluno({ ...aluno, ficha_adicionais: { ...aluno.ficha_adicionais, irmaos: e.target.value } })}
                            />
                        </div>

                    </Col>
                    <Col >
                        <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                                Restrição familiar
                            </Label>
                            <Input

                                type="text"
                                className="form-control"
                                id="basicpill-cardno-input12"
                                placeholder="Restrição familiar..."
                                defaultValue={aluno.ficha_adicionais.restricao_familiar}
                                onBlur={(e) => setAluno({ ...aluno, ficha_adicionais: { ...aluno.ficha_adicionais, restricao_familiar: e.target.value } })}
                            />
                        </div>

                    </Col>
                    <Col >
                        <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                                Familia refugiada
                            </Label>
                            <select className="form-select" onChange={(e) => {
                                if (e.target.value == "Sim")
                                    setAluno({ ...aluno, ficha_adicionais: { ...aluno.ficha_adicionais, familia_refugiada: 1 } })
                                if (e.target.value == "Não")
                                    setAluno({ ...aluno, ficha_adicionais: { ...aluno.ficha_adicionais, familia_refugiada: 0, origem: null } })

                            }}>
                                <option selected={aluno.ficha_adicionais.familia_refugiada === "Não" ? "selected" : ""} value="Não">Não</option>
                                <option selected={aluno.ficha_adicionais.familia_refugiada === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                            </select>
                        </div>
                    </Col>
                    {parseBoolean(aluno.ficha_adicionais.familia_refugiada) &&
                        <Col>
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Origem
                                </Label>

                                <Input

                                    id="basicpill-address-input1"
                                    className="form-control"
                                    rows="1"
                                    placeholder="Origem..."
                                    defaultValue={aluno.ficha_adicionais.origem}
                                    onBlur={(e) => setAluno({ ...aluno, ficha_adicionais: { ...aluno.ficha_adicionais, origem: e.target.value } })}

                                />
                            </div>
                        </Col>}



                    <Col >
                        <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                                Observações
                            </Label>
                            <Input

                                type="text"
                                className="form-control"
                                id="basicpill-cardno-input12"
                                placeholder="Observações..."
                                defaultValue={aluno.ficha_adicionais.observacoes}
                                onBlur={(e) => setAluno({ ...aluno, ficha_adicionais: { ...aluno.ficha_adicionais, observacoes: e.target.value } })}
                            />
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col >
                        <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                                Tem Cadastro único
                            </Label>
                            <select className="form-select" onChange={(e) => {
                                if (e.target.value == "Sim")
                                    setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, cadastro_unico: "Sim" } })
                                if (e.target.value == "Não")
                                    setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, cadastro_unico: "Não", qual_cras: null } })

                            }}>
                                <option selected={aluno.ficha_socio.cadastro_unico === "Não" ? "selected" : ""} value="Não">Não</option>
                                <option selected={aluno.ficha_socio.cadastro_unico === "Sim" ? "selected" : ""} value="Sim">Sim</option>

                            </select>
                        </div>

                    </Col>
                    {parseBoolean(aluno.ficha_socio.cadastro_unico) &&
                        <Col>
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Qual CRAS
                                </Label>

                                <Input

                                    id="basicpill-address-input1"
                                    className="form-control"
                                    rows="1"
                                    placeholder="Qual CRAS..."
                                    defaultValue={aluno.ficha_socio.qual_cras}
                                    onBlur={(e) => setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, qual_cras: e.target.value } })}
                                />
                            </div>
                        </Col>}
                    <Col >
                        <div className="mb-3">
                            <Label for="basicpill-lastname-input2">
                                Benefício Socioassistencial
                            </Label>
                            <select className="form-select" onChange={(e) => {

                                if (e.target.value == "Sim")
                                    return setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_qual: "Auxilio Brasil", beneficio_outros: null, valor_pensao: null, beneficio_socio: "Sim" } })
                                if (e.target.value == "Não")
                                    return setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_qual: null, beneficio_outros: null, valor_pensao: null, beneficio_socio: "Não" } })
                                //setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_socio: e.target.value } })
                            }}>
                                <option selected={aluno.ficha_socio.beneficio_socio === "Sim" ? "selected" : ""} value="Sim">Sim</option>
                                <option selected={aluno.ficha_socio.beneficio_socio === "Não" ? "selected" : ""} value="Não">Não</option>


                            </select>
                        </div>

                    </Col>

                    {parseBoolean(aluno.ficha_socio.beneficio_socio) &&


                        <Col>

                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Qual benefício
                                </Label>



                                <select className="form-select" onChange={(e) => {


                                    if (e.target.value.includes("Pensao"))
                                        return setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_qual: e.target.value, beneficio_outros: null } })

                                    if (e.target.value.includes("Outros"))
                                        return setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_qual: e.target.value, valor_pensao: null } })

                                    if (e.target.value.includes("Bolsa Família"))
                                        return setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_qual: e.target.value, valor_pensao: null, beneficio_outros: null } })

                                    setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_qual: e.target.value, beneficio_outros: null, valor_pensao: null } })
                                }}>
                                    {beneficios.map((beneficio_qual, index) => {
                                        return <option selected={aluno.ficha_socio.beneficio_qual == beneficio_qual} key={index} value={beneficio_qual}>{beneficio_qual}</option>
                                    })}

                                </select>
                            </div>
                        </Col>}
                    {parseBoolean(aluno.ficha_socio.beneficio_qual == "Outros") &&
                        <Col>
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Qual
                                </Label>

                                <Input

                                    id="basicpill-address-input1"
                                    className="form-control"
                                    rows="1"
                                    placeholder="Qual benefício..."
                                    defaultValue={aluno.ficha_socio.beneficio_outros}
                                    onBlur={(e) => setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_outros: e.target.value } })}
                                />

                            </div>
                        </Col>}
                    {(aluno.ficha_socio.beneficio_qual == "Pensao") &&
                        <Col>
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Valor
                                </Label>

                                <Input

                                    id="basicpill-address-input1"
                                    className="form-control"
                                    rows="1"
                                    placeholder="Valor..."
                                    defaultValue={aluno.ficha_socio.valor_pensao}
                                    onBlur={(e) => setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, valor_pensao: e.target.value } })}
                                />

                            </div>
                        </Col>}
                    {(aluno.ficha_socio.beneficio_qual == "Bolsa Família") &&
                        <Col>
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Numero do cartão
                                </Label>

                                <Input
                                    id="basicpill-address-input1"
                                    className="form-control"
                                    rows="1"
                                    placeholder=" Numero do cartão..."
                                    type="number"
                                    defaultValue={aluno.ficha_socio.beneficio_numero_cartao}
                                    onBlur={(e) => setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, beneficio_numero_cartao: e.target.value } })}
                                />

                            </div>
                        </Col>}
                    <Row>
                        <Col >
                            <div className="mb-3">
                                <Label for="basicpill-lastname-input2">
                                    Atendimento
                                </Label>
                                <select className="form-select" onChange={(e) => {

                                    if (e.target.value == "Não foi atendido(a)")
                                        return setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, foi_atendido: "Não foi atendido(a)", motivo_atendimento: null } })

                                    setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, foi_atendido: e.target.value } })
                                }}>
                                    <option selected={aluno.ficha_socio.beneficio_socio === "Conselho Tutelar" ? "selected" : ""} value="Conselho Tutelar">Conselho Tutelar </option>
                                    <option selected={aluno.ficha_socio.beneficio_socio === "CREAS/PAEFI" ? "selected" : ""} value="CREAS/PAEFI">CREAS/PAEFI</option>
                                    <option selected={aluno.ficha_socio.beneficio_socio === "CAPSi" ? "selected" : ""} value="CAPSi">CAPSi</option>
                                    <option selected={aluno.ficha_socio.beneficio_socio === "Não foi atendido(a)" ? "selected" : ""} value="Não foi atendido(a)">Não foi atendida</option>
                                </select>
                            </div>

                        </Col>
                        {(aluno.ficha_socio.foi_atendido != "Não foi atendido(a)") &&
                            <Col >
                                <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                        Motivo do atendimento
                                    </Label>
                                    <Input

                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="1"
                                        placeholder="Motivo do atendimento..."
                                        defaultValue={aluno.ficha_socio.motivo_atendimento}
                                        onBlur={(e) => setAluno({ ...aluno, ficha_socio: { ...aluno.ficha_socio, motivo_atendimento: e.target.value } })}
                                    />

                                </div>

                            </Col>}
                    </Row>

                </Row>

            </div>
        </div>
    );
}

export default ImprimirAlunos;