import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

import AlunoWizard from "pages/Alunos/form/create"
import ListaDeAlunos from "../pages/Alunos"
import AlunoUpdateFormWizard from "pages/Alunos/form/update"
import ImprimirAlunos from "pages/Alunos/form/print"

const authProtectedRoutes = [
  { path: "/alunos", component: ListaDeAlunos },
  { path: "/aluno/:id", component: AlunoUpdateFormWizard },
  { path: "/aluno", component: AlunoWizard },
  { path: "/aluno/imprimir/:id", component: ImprimirAlunos },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/alunos" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
